import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="lsqhmscb4l"
      title="How Rhombus Can Help West Virginia Schools Monitor Special Education Environments"
      description="Discover an efficient and cost-effective way for West Virginia schools to meet the monitoring requirements of House Bill 3271 while maintaining the privacy and confidentiality of students and teachers."
    />
  );
}
